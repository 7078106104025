<template>
  <section class="height-spacer">
    <SectionTitle>
      <template #title>
        Retrouvez les informations du monde associatif
      </template>
      <template #content>
        <SeoLink
          :external="true"
          to="/blog"
          data-ux="home_blog_link"
          text="Découvrir notre blog"
        />
      </template>
    </SectionTitle>
    <Grid
      tag="aside"
      layout="3"
      spacing="medium"
    >
      <BlogArticlePreview
        v-for="article of articles"
        :key="article.articleUrl"
        :article="article"
      />
    </Grid>
  </section>
</template>

<script setup lang="ts">
import SeoLink from '@/domains/design_system/molecules/SeoLink/SeoLink.vue'
import BlogArticlePreview from '@/domains/design_system/molecules/BlogArticlePreview/BlogArticlePreview.vue'
import SectionTitle from '@/domains/design_system/molecules/SectionTitle.vue'
import Grid from '@/domains/design_system/organisms/Grid.vue'

import { fetchBlogArticles } from '@/services/fetchBlogArticles.service'

const articles = fetchBlogArticles()
</script>

<style scoped lang="scss">
section {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
</style>
